<template>
  <div class="nk-block">
    <div class="nk-block-head">
      <h5>{{ $t('Personal Information') }}</h5>
      <p>{{ $t('advisor_shared_info_msg')}}</p>
    </div>
    <profile-ud-list>
      <profile-ud-item :label="$t('First Name')" :value="userData.first_name"></profile-ud-item>
      <profile-ud-item :label="$t('Last Name')" :value="userData.last_name"></profile-ud-item>
      <profile-ud-item :label="$t('Company')" :value="userData.company"></profile-ud-item>
      <template v-if="userRole === 'is-admin'">
        <profile-ud-item :label="$t('AFM number')" :value="userData.afm_number"></profile-ud-item>
        <profile-ud-item :label="$t('Chamber of commerce number')" :value="userData.ch_com_number"></profile-ud-item>
      </template>
    </profile-ud-list>
  </div>
  <div class="nk-block">
    <div class="nk-block-head nk-block-head-line">
      <h6 class="title overline-title text-base">{{ $t('Contact Information') }}</h6>
    </div>
    <profile-ud-list>
      <profile-ud-item :label="$t('Phone number')" :value="userData.phone_number"></profile-ud-item>
      <profile-ud-item label="Postcode" :value="userData.post_code"></profile-ud-item>
      <profile-ud-item :label="$t('City')" :value="userData.city"></profile-ud-item>
      <profile-ud-item :label="$t('Province')" :value="userData.province"></profile-ud-item>
      <profile-ud-item :label="$t('Address')" :value="userData.house_no+', '+userData.street"></profile-ud-item>
    </profile-ud-list>
  </div>
</template>

<script>
import ProfileUdList from "@/views/admin/advisors/components/ProfileUdList";
import ProfileUdItem from "@/views/admin/advisors/components/ProfileUdItem";
import useStoreHelper from '@/store/helper'

export default {
  components: { ProfileUdItem, ProfileUdList},
  props: {
    userData:{
      required: true,
      type: Object,
    }
  },
  setup(){
    const{userRole} = useStoreHelper()
    return{
      userRole,
    }
  },
}
</script>
