<template>
  <div class="profile-stats">
    <span class="amount">{{ value}}</span>
    <span class="sub-text">{{ label}}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: null,
  }
}
</script>
