import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";
import {useI18n} from "vue-i18n";
import useStoreHelper from '@/store/helper'

export default () => {

    const {endPointByRole} = useStoreHelper()
    const {t} = useI18n()
    const isDesktop = isPlatform('desktop')
    /**
     * @param AL_ids assigned leads ids
     * @param leadType possible value lead | appointment
     * @returns {Promise<unknown>}
     */
    const withdrawLeads = async ( AL_ids, leadType ) => {
        if(!leadType){
            leadType = 'lead';
        }
        const loading = await loadingController.create({message: t('Processing...')})
        const toast   = await toastController.create({position: "top", duration: 3000})
        return new Promise((resolve, reject)=>{
            alertController.create({
                cssClass: isDesktop ? 'alert-web' : '',
                header: `${t('Withdraw') +' '+t(leadType+(AL_ids.length > 1 ? 's' : ''))}`,
                message: t('action_confirmation',{action: t('withdraw'), count: AL_ids.length, type: t(leadType+ (AL_ids.length > 1 ? 's': '')) }),
                buttons: [
                    {
                        text: t('Cancel'),
                        cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                    },
                    {
                        cssClass: isDesktop ? 'alert-btn-warning' : '',
                        text: t('Confirm'), handler: () => {

                            loading.present()
                            axios.post(endPointByRole+'/leads/assigned/withdraw', {ids: JSON.stringify(AL_ids), leadType: leadType})
                                .then( resp => {
                                    toast.message = resp.data.success ? t('lead_withdraw_success_msg') : resp.data.message
                                    toast.color   = resp.data.success ? "primary" : "danger"
                                    if(resp.data.success){
                                        resolve(AL_ids)
                                    }
                                    else{
                                        reject(resp.data.message)
                                    }
                                    toast.present()
                                })
                                .catch(error => {
                                    toast.message = t('Error')+': '+(error.response ? error.response.status+' '+error.response.statusText : t('errors.general_error'))
                                    toast.color = "danger"
                                    toast.present()
                                    reject(toast.message)
                                })
                                .then( () => loading.dismiss())
                        }
                    },
                ]
            }).then((a) => a.present())
        })
    }

    const advisorsLeadsCount = async ( userIds, paidStatus, dateRange, timeFilter, lead_status ) => {
        return new Promise((resolve, reject)=>{
            const d = {
                user_ids: JSON.stringify(userIds),
                paid_status: paidStatus,
                date: dateRange,
                time_filter: timeFilter,
                lead_status: lead_status,
            }
            axios.post('/api/admin/advisors/leads/count',d)
                .then(r=>{
                    if(r.data.success){
                        resolve(r.data.data)
                    }
                    else{
                        reject(r.data.message)
                    }
                })
                .catch(er=>{
                    reject(er.response ? er.response.status+' '+er.response.statusText : t('errors.general_error'))
                })
        })
    }

    return {
        advisorsLeadsCount,
        withdrawLeads
    }
}
