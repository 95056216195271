<template>
  <div class="nk-block-head d-flex justify-content-between">
    <h5>{{ $t('Notes') }}</h5>
    <nk-button v-if="['is-admin', 'is-company'].includes(userRole)" type="primary" size="sm" is-icon-only-button v-on:click="[addEditNote.modalOpen=true, Object.assign(addEditNote.note, {id: 0,note: ''})]">
      <nio-icon icon="ni-plus"></nio-icon>
    </nk-button>
  </div>
  <div class="text-center" v-if="loading">
    <ion-spinner></ion-spinner>
  </div>
  <template v-else>
    <div class="lead_note_single" v-for="(note, index) in userNotes.records" :key="'ln'+index">
      <div class="d-flex justify-between">
        <div>{{note.note}}</div>
        <ul class="nk-tb-actions gx-1" v-if="['is-admin', 'is-company'].includes(userRole)">
          <li>
            <drop-down-list>
              <list-item icon="ni-edit" :text="$t('Edit')" v-on:click="editNote(note)"></list-item>
              <list-item icon="ni-trash-empty" :text="$t('Delete')" v-on:click="F_deleteNote(note.id)"></list-item>
            </drop-down-list>
          </li>
        </ul>
      </div>
      <div class="small mt-1 nk-text-dark">{{note.created_at}}, {{$t('added by')+' '+note.added_by}}</div>
    </div>
    <div class="text-muted" v-if="!userNotes.records.length">{{$t('No notes found.')}}</div>
  </template>
  <ion-modal
      :is-open="addEditNote.modalOpen"
      :css-class="isDesktop ? 'modal-web': ''"
      @didDismiss="addEditNote.modalOpen=false">
    <i-modal
        :title="$t(parseInt(addEditNote.note.id) > 0 ? 'Update Note' : 'Add Note')"
        @modal-close="addEditNote.modalOpen=false">
      <textarea class="form-control" v-model="addEditNote.note.note"></textarea>
      <template v-slot:footer>
        <nk-button type="primary" size="sm" v-on:click="F_addUserNote">
          <nio-icon icon="ni ni-plus" v-if="parseInt(addEditNote.note.id) < 1"></nio-icon> {{$t(parseInt(addEditNote.note.id) > 0 ? 'Update Note' : 'Add Note')}}</nk-button>
      </template>
    </i-modal>
  </ion-modal>
</template>

<script>
import {reactive, ref, watch} from "vue";
import {IonModal, IonSpinner, isPlatform, loadingController, toastController} from "@ionic/vue";
import useAdvisors from '../useAdvisors'
import {useI18n} from "vue-i18n";
import IModal from "@core/components/ion-modal/IModal";
import DropDownList from "@core/components/dropdown-list/DropDownList";
import ListItem from "@core/components/dropdown-list/ListItem";
import useStoreHelper from '@/store/helper'

export default {
  components: {ListItem, DropDownList, IModal, IonModal, IonSpinner},
  props: {
    currentTab: String,
    userId: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  setup( props ){

    let userNotes = reactive({
      total_records: 0,
      records: [],
    });
    let loading = ref(false)
    const{addUserNote, deleteNote, getUserNotes} = useAdvisors()
    const {t} = useI18n()
    const isDesktop = isPlatform('desktop')
    const {userRole} = useStoreHelper()

    // Get advisor notes
    const F_getUserNotes = async ()=>{
      loading.value = true
      getUserNotes(props.userId).then((r)=>{
        userNotes.total_records = r.total_records
        userNotes.records = r.records
      }).catch((m)=>{
            toastController.create({header: t('Error'), message: m, duration: 3500, position: 'top', color: 'danger'}).then((t)=> t.present())
      }).then(()=> loading.value = false)
    }
    const F_addUserNote = async ()=>{
      const l = await loadingController.create({message: t('Please wait...')});
      await l.present()
      addUserNote(addEditNote.note).then((r)=>{
        if(parseInt(addEditNote.note.id) < 1){
          userNotes.records.unshift(r)
        }
        else{
          for(let x in userNotes.records){
            if(parseInt(userNotes.records[x].id) === parseInt(addEditNote.note.id)){
              userNotes.records[x].note = addEditNote.note.note
              break;
            }
          }
        }
        addEditNote.modalOpen = false
      }).catch((m)=>{
        toastController.create({header: t('Error'), message: m, duration: 3500, position: 'top', color: 'danger'}).then((t)=> t.present())
      }).then(()=> l.dismiss())
    }
    const F_deleteNote = async (id, index)=>{
      deleteNote(id).then(()=>{
        userNotes.records.splice(index, 1)
      }).catch((m)=>{
        toastController.create({header: t('Error'), message: m, duration: 3500, position: 'top', color: 'danger'}).then((t)=> t.present())
      })
    }

    let addEditNote = reactive({
      modalOpen: false,
      note:{
        id: 0,
        note: '',
        user_id: props.userId,
      }
    });

    const editNote = (note)=>{
      addEditNote.note.id = note.id
      addEditNote.note.note = note.note
      addEditNote.modalOpen = true
    }

    watch( () => props.currentTab, (n) => {
      if(n === 'notes' && !userNotes.records.length){
        F_getUserNotes()
      }
    })

    return {
      addEditNote,
      editNote,
      F_addUserNote,
      F_deleteNote,
      F_getUserNotes,
      isDesktop,
      loading,
      userNotes,
      userRole,
    }

  }
}
</script>
