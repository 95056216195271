<template>
  <div class="custom-control custom-radio">
    <input type="radio" :id="id" :name="name" class="custom-control-input" :value="value" @change="$emit('update:modelValue', $event.target.value)"
           :checked="modelValue === value">
    <label class="custom-control-label" :for="id">
      <template v-if="hasSlotContent">
        <slot name="label"></slot>
      </template>
      <template v-else>{{ $t(label) }}</template>
    </label>
  </div>
</template>

<script>
import {defineComponent,ref} from "vue"

export default defineComponent({
  props: {
    value: null,
    id: {
      type: String,
      default: 'radio',
    },
    name: {
      type: String,
      default: 'radio',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: null,
  },
  setup(props, {slots}){
    let hasSlotContent = ref(false)
    hasSlotContent.value = slots.label && slots.label().length
    return{
      hasSlotContent,
    }
  }
})
</script>
