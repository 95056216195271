<template>
  <div class="nk-block-head">
    <h5>{{ $t('Account Activity') }}</h5>
  </div>
  <div class="text-center" v-if="loading">
    <ion-spinner></ion-spinner>
  </div>
  <table class="table table-ulogs" v-else>
    <thead class="thead-light">
    <tr>
      <th class="tb-col-os"><span class="overline-title">{{ $t('Browser') }} <span class="d-sm-none">/ IP</span></span></th>
      <th class="tb-col-ip"><span class="overline-title">IP</span></th>
      <th class="tb-col-time"><span class="overline-title">{{ $t('Time') }}</span></th>
      <th class=""><span class="overline-title">{{ $t('Event') }}</span></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="log in activityLog.data" :key="log.id">
      <td class="tb-col-os">{{ log.user_agent }}</td>
      <td class=""><span class="sub-text">{{ log.ip }}</span></td>
      <td class="tb-col-time"><span class="sub-text">{{ log.created_at }}</span></td>
      <td class=""><span class="sub-text">{{ log.activity_type }}</span></td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {reactive, ref, watch} from "vue";
import axios from "@/libs/axios";
import {IonSpinner} from "@ionic/vue"

export default {
  components:{IonSpinner},
  props: {
    currentTab: String,
    userId: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  setup( props ){

    let activityLog = reactive({
      data: [],
      total_records: 0,
      current_page: 1,
    })
    let loading = ref(false)

    // Get activity log
    const getActivityLog = (reset) => {
      loading.value = true
      axios.post('/api/admin/advisors/'+props.userId+'/activity_log')
          .then( resp => {
            if(resp.data.success){
              if(reset){
                activityLog.data = []
              }
              for(let x in resp.data.data){
                activityLog.data.push(resp.data.data[x])
              }
              if(activityLog.current_page === 1){
                activityLog.total_records = resp.data.total_records
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      .then(()=> loading.value = false)
    }

    watch( () => props.currentTab, (n) => {
      if(n === 'activities' && !activityLog.data.length){
        getActivityLog()
      }
    })

    return {
      activityLog,
      loading,
    }

  }
}
</script>
