<template>
  <div class="card-aside-wrap">
    <div class="card-content">
      <slot name="contentWrapper"></slot>
    </div>
    <div class="card-aside user-aside toggle-slide toggle-slide-left toggle-break-lg"
         :class="asideClasses"
         data-toggle-screen="lg" data-toggle-overlay="true">
      <div class="card-inner-group" data-simplebar>
        <ion-header v-if="isPlatform('hybrid')" class="ion-no-border">
          <ion-toolbar class="is-ion-safe-area">
            <ion-title>{{ $t('Account') }}</ion-title>
          </ion-toolbar>
        </ion-header>
        <slot name="aside"></slot>
      </div>
    </div>
    <div class="toggle-overlay" v-if="asideActive" v-on:click="toggleAside"></div>
  </div>
</template>

<script>
import {computed} from "vue";
import {isPlatform} from "@ionic/vue";

export default {
  props: {
    sidebarPosition: {
      type: String,
      default: 'left',
    },
    asideActive: Boolean,
  },
  emits: ['toggleSidebar'],
  setup( props, {emit} ){

    let asideClasses = computed(()=>{
      let classes = [`card-aside-${props.sidebarPosition}`]
      if(props.asideActive){
        classes.push('content-active')
      }
      return classes
    })

    const toggleAside = () => emit('toggleSidebar')

    return {
      asideClasses,
      isPlatform,
      toggleAside,
    }
  },
}
</script>
