<template>
  <div class="nk-block-head px-4">
    <h5>{{$t('Assigned appointments')}}</h5>
    <p>{{$t('pages.admin_advisor_profile.appointments_tab_desc')}}</p>
  </div>
  <div v-if="loading" class="text-center">
    <ion-spinner class="position-absolute"></ion-spinner>
  </div>
  <div class="card-inner p-0" :style="[loading ? 'filter:blur(2px);' : '']">
    <special-table>
      <table-row is-heading>
        <table-col>
          <span class="sub-text">Lead#</span>
        </table-col>
        <table-col>
          <span class="sub-text">Lead</span>
        </table-col>
        <table-col break-point="md">{{ $t('Assigned by') }}</table-col>
        <table-col break-point="md">{{ $t('Assign date') }}</table-col>
        <table-col class="nk-tb-col-tools"></table-col>
      </table-row>
      <table-row v-for="lead in appointments.records" :key="'ld'+lead.id">
        <table-col>{{ lead.lead_number }}</table-col>
        <table-col>
          <user-small-card
              :user-name="lead.first_name+' '+lead.last_name"
              :user-email="lead.email" :image="lead.dp" :initials="lead.initials"></user-small-card>
        </table-col>
        <table-col break-point="md">
          <user-small-card v-if="lead.assigned_by.first_name"
              :user-name="lead.assigned_by.first_name+' '+lead.assigned_by.last_name"
              :user-email="lead.assigned_by.email" :image="lead.assigned_by.dp" :initials="lead.assigned_by.initials"></user-small-card>
          <span class="text-muted" v-else>{{$t('Not yet assigned')}}</span>
        </table-col>
        <table-col break-point="md" class="lh-n">
          {{ lead.assign_date.date}}
          <span class="sub-text">{{ lead.assign_date.time_passed+' '+$t('ago')}}</span>
        </table-col>
        <table-col class="nk-tb-col-tools">
          <ul class="nk-tb-actions gx-1">
            <li>
              <drop-down-list>
                <list-item v-if="userRole === 'is-admin'" icon="ni-eye" :text="$t('View Details')" :to="{name: 'adminLeadDetails', params:{id:lead.id}}"></list-item>
                <list-item :class="{disabled: lead.opened_at}" icon="ni-curve-left-down" :text="$t('Withdraw')"
                           v-on:click="lead.opened_at ? '' : F_withdrawLeads([lead.AL_id])"></list-item>
              </drop-down-list>
            </li>
          </ul>
        </table-col>
      </table-row>

    </special-table>

  </div>
  <div class="card-inner" v-if="appointments.total_records > 10">
    <pagination
        v-model="appointments.current_page"
        :records="appointments.total_records"
        :per-page="10"
        @paginate="paginationCallback"
        theme="bootstrap4"
        :options="{chunk: 5, texts:{count: '||'}}"/>
  </div>
</template>

<script>
import Pagination from "v-pagination-3"
import {reactive, ref, watch} from "vue";
import axios from "@/libs/axios";

import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import {IonSpinner} from "@ionic/vue"
import useStoreHelper from '@/store/helper'
import useAdminLeadsFunctions from '@core/comp-functions/admin/AdminLeadFunctions'

export default {
  components: {
    DropDownList,
    IonSpinner,
    ListItem,
    Pagination,
    SpecialTable,
    TableRow,
    TableCol,
    UserSmallCard,
  },
  props: {
    userId: {
      required: true,
    },
    currentTab: String,
  },
  setup( props ){

    const{endPointByRole, userRole} = useStoreHelper()
    const{withdrawLeads} = useAdminLeadsFunctions()
    let appointments = reactive({
      records: [],
      total_records: 0,
      current_page: 1,
    })
    let loading = ref(false)

    const getAppointments = () => {
      loading.value = true
      axios.post(endPointByRole+'/advisors/'+props.userId+'/appointments')
          .then( resp => {
            if(resp.data.success){
              appointments.records = resp.data.data.records
              if(appointments.current_page === 1){
                appointments.total_records = resp.data.data.total_records
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      .then(()=> loading.value = false)
    }

    watch( () => props.currentTab, (n) => {
      if(n === 'appointments'){
        getAppointments()
      }
    })

    const F_withdrawLeads = async ( AL_ids ) => {
      withdrawLeads(AL_ids, 'appointment').then(ids=>{
        // Remove the selected leads from the leads array
        for(let x in ids){
          for(let y in appointments.records){
            if(appointments.records[y].AL_id === ids[x]){
              appointments.records.splice(y,1)
              break;
            }
          }
        }
        // All deleted, no entry left in the records array? and previous pages exists
        if( !appointments.records.length && appointments.current_page > 1 ){
          // Deduct from total records value
          appointments.total_records -= ids.length
          // Goto previous page
          appointments.current_page--
          getAppointments()
        }
      }).catch(()=>{})
    }

    return {
      appointments,
      F_withdrawLeads,
      loading,
      userRole,
    }

  }
}
</script>
