<template>
  <div class="nk-block-head px-4 pb-0">
    <h5>{{$t('Assigned')}} Leads</h5>
  </div>
  <div class="my-2 p-4 bg-secondary-dim">
    <row>
      <column default="12" :md="6" :lg="3">
        <label class="overline-title overline-title-alt mb-0" for="d_status_f">{{$t('Assign date')}}</label>
        <vue-flatpickr id="d_range_f"
                       class="form-control" :config="vueFlatPickerConfig"
                       @on-change="onDateFilterChange"
                       placeholder="Date filter"></vue-flatpickr>
      </column>
      <column default="12" md="6" :lg="3">
        <label class="overline-title overline-title-alt mb-0" for="d_status_f">{{$t('Status')}}</label>
        <b-select v-model="assignedLeads.filters.status" id="d_status_f" v-on:change="getAssignedLeads(true)">
          <option value="0">{{$t('All')}}</option>
          <option v-for="status in leadStatuses" :key="'ls'+status.id" :value="status.id">{{ $t(status.status_text) }}</option>
        </b-select>
      </column>
    </row>
  </div>
  <div v-if="loading" class="text-center">
    <ion-spinner></ion-spinner>
  </div>
  <div v-else>
  <div class="card-inner p-0">
    <special-table>
      <table-row is-heading>
        <table-col>
          <span class="sub-text">Lead#</span>
        </table-col>
        <table-col>
          <span class="sub-text">Lead</span>
        </table-col>
        <table-col break-point="mb">{{ $t('Phone') }}</table-col>
        <table-col break-point="md">{{ $t('Assign date') }}</table-col>
        <table-col break-point="lg">{{ $t('Opened') }}</table-col>
        <table-col break-point="lg">{{ $t('Status') }}</table-col>
        <table-col class="nk-tb-col-tools"></table-col>
      </table-row>
      <table-row v-for="lead in assignedLeads.data" :key="'ld'+lead.id">
        <table-col>{{ lead.lead_number }}</table-col>
        <table-col>
          <user-small-card
              :user-name="lead.first_name+' '+lead.last_name"
              :user-email="lead.email" :image="lead.dp" :initials="lead.initials"></user-small-card>
        </table-col>
        <table-col break-point="mb">
          <span class="tb-lead">{{ lead.phone_no }}</span>
        </table-col>
        <table-col break-point="md">{{ lead.assign_date }}</table-col>
        <table-col break-point="lg">{{ lead.opened_time }}</table-col>
        <table-col break-point="lg">{{ lead.status_text }}</table-col>
        <table-col class="nk-tb-col-tools">
          <ul class="nk-tb-actions gx-1">
            <li>
              <drop-down-list>
                <list-item icon="ni-eye" :text="$t('View Details')"
                           :to="{name: (userRole === 'is-admin' ? 'adminLeadDetails' : 'comLeadDetails'), params:{id: userRole === 'is-admin' ? lead.id : lead.AL_id }}"></list-item>
                <list-item icon="ni-curve-left-down" :text="$t('Withdraw')" v-on:click="F_withdrawLeads([lead.AL_id])"></list-item>
              </drop-down-list>
            </li>
          </ul>
        </table-col>
      </table-row>

    </special-table>

  </div>
  <div class="card-inner" v-if="assignedLeads.total_records > 10">
    <pagination
        v-model="assignedLeads.current_page"
        :records="assignedLeads.total_records"
        :per-page="10"
        @paginate="paginationCallback"
        theme="bootstrap4"
        :options="{chunk: 5, texts:{count: '||'}}"/>
  </div>
  </div>
</template>

<script>
import Pagination from "v-pagination-3"
import {computed, onMounted, reactive, ref, watch} from "vue";
import axios from "@/libs/axios";
import {IonSpinner} from "@ionic/vue";

import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import useAdminLeadFunction from "@core/comp-functions/admin/AdminLeadFunctions"
import VueFlatpickr from "vue-flatpickr-component"
import 'flatpickr/dist/flatpickr.css';
import {Dutch} from "flatpickr/dist/l10n/nl";
import {english} from "flatpickr/dist/l10n/default"
import {useStore} from "vuex";
import {Column, Row} from "@core/layouts";
import BSelect from "@core/components/bp-form/components/BSelect";
import useStoreHelper from '@/store/helper'

export default {
  components: {
    BSelect,
    Row,
    Column,
    DropDownList,
    IonSpinner,
    ListItem,
    Pagination,
    SpecialTable,
    TableRow,
    TableCol,
    UserSmallCard,
    VueFlatpickr,
  },
  props: {
    currentTab: String,
    userId: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  setup( props ){

    const {withdrawLeads} = useAdminLeadFunction()
    const{endPointByRole, userRole} = useStoreHelper()
    const store = useStore()
    let assignedLeads = reactive({
      data: [],
      filters:{
        date_from: '',
        date_to: '',
        status: 0,
      },
      total_records: 0,
      current_page: 1,
    })
    let loading = ref(false)

    // Get activity log
    const getAssignedLeads = (reset) => {
      loading.value = true
      if(reset){
        assignedLeads.current_page = 1;
      }
      let params = Object.assign({}, assignedLeads.filters)
      params.page = assignedLeads.current_page;
      axios.post(endPointByRole+'/advisors/'+props.userId+'/leads', params)
          .then( resp => {
            if(resp.data.success){
              assignedLeads.data = resp.data.data;
              if(assignedLeads.current_page === 1){
                assignedLeads.total_records = resp.data.total_records
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      .then(()=> loading.value = false)
    }
    const F_withdrawLeads = async ( AL_Ids ) => {
      withdrawLeads(AL_Ids, 'lead').then(ids=>{
        // Remove the selected leads from the leads array
        for(let x in ids){
          for(let y in assignedLeads.data){
            if(assignedLeads.data[y].AL_id === ids[x]){
              assignedLeads.data.splice(y,1)
              break;
            }
          }
        }
        // All deleted, no entry left in the advisor array? and previous pages exists
        if( !assignedLeads.data.length && assignedLeads.current_page > 1 ){
          // Deduct from total records value
          assignedLeads.total_records -= ids.length
          // Goto previous page
          assignedLeads.current_page--
          getAssignedLeads(true)
        }
      }).catch(()=>{})
    }

    onMounted(()=>{
      if(props.currentTab === 'leads'){
        getAssignedLeads()
      }
    })
    watch( () => props.currentTab, (n) => {
      assignedLeads.filters.date_from = assignedLeads.filters.date_to = ''
      if(n === 'leads' && !assignedLeads.data.length){
        getAssignedLeads()
      }
    })

    const paginationCallback = () => {
      getAssignedLeads()
    }

    const onDateFilterChange = (a,b) => {
      assignedLeads.filters.date_from = b ? b.substr(0, 10) : ''
      assignedLeads.filters.date_to = (b && b.length > 12) ? b.substr(-10) : ''
      getAssignedLeads(true)
    }

    const vueFlatPickerConfig = {
      altFormat: 'd/m/Y',
      altInput: true,
      mode: 'range',
      locale: store.state.appConfig.lang === 'nl' ? Dutch: english,
    }

    return {
      assignedLeads,
      leadStatuses: computed(() => store.state.general_data.leads_statuses),
      F_withdrawLeads,
      getAssignedLeads,
      loading,
      onDateFilterChange,
      paginationCallback,
      userRole,
      vueFlatPickerConfig,
    }

  }
}
</script>
