<template>
  <page-template>
    <main-block>
      <div v-if="loadingData" class="mb-4">
        <ion-skeleton-text style="width: 25%;height: 30px" animated></ion-skeleton-text>
        <ion-skeleton-text style="width: 25%;height: 30px" animated></ion-skeleton-text>
      </div>
      <block-head v-else :title="$t('Advisors')+' /' ">
        <template v-slot:title_extras>
          <strong class="text-primary small">{{ advisorData.info.first_name+' '+advisorData.info.last_name}}</strong>
        </template>
        <template v-slot:description_extras>
          <list-inline>
            <list-inline-item>{{$t('Advisor')}} ID: <span class="text-base">{{advisorData.info.id}}</span></list-inline-item>
            <list-inline-item>{{$t('Last Login')}}: <span class="text-base">{{advisorData.info.last_login.date}}</span></list-inline-item>
          </list-inline>
        </template>
        <template v-slot:default>
          <nk-button type="primary" v-if="ionRouter.canGoBack()" outline v-on:click="$router.go(-1)"><nio-icon icon="ni-arrow-left"></nio-icon> {{$t('Back')}}</nk-button>
        </template>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <card-aside sidebar-position="right" :aside-active="asideActive" @toggle-sidebar="asideStatus">
            <template v-slot:contentWrapper>
              <nav-tabs tab-icons nav-tabs-card>
                <template v-slot:tabs>
                  <template v-for="(tab,index) in tabs" :key="'tb'+index">
                    <nav-tab-item v-if="!tab.required_roles || tab.required_roles.includes(userRole)"
                                  :tab-id="index"
                                  :tab-name="$t(tab.name)"
                                  :icon="tab.icon"
                                  :current-tab="currentTab"
                                  @switch-tab="switchTab"></nav-tab-item>
                  </template>
                  <nav-tab-item tab-name="" tab-id="t_action" icon="ni-user-list-fill" class="nav-item-trigger d-xxl-none" v-on:click="asideActive=true"></nav-tab-item>
                </template>
                <template v-slot:content>
                  <div class="py-4 text-center" v-if="loadingData">
                    <ion-spinner></ion-spinner>
                  </div>
                  <template v-else>
                    <nav-tab-pane tab-id="p_info" :class="{active: currentTab === 'p_info'}" class="nav-tabs-card">
                      <personal-info :user-data="advisorData.info"></personal-info>
                    </nav-tab-pane>
                    <nav-tab-pane v-if="['is-admin', 'is-company'].includes(userRole)" tab-id="leads" :class="{active: currentTab === 'leads'}">
                      <assigned-leads :current-tab="currentTab" :user-id="advisorData.info.id"></assigned-leads>
                    </nav-tab-pane>
                    <nav-tab-pane tab-id="leads" :class="{active: currentTab === 'appointments'}">
                      <assigned-appointments :current-tab="currentTab" :user-id="advisorData.info.user_id"></assigned-appointments>
                    </nav-tab-pane>
                    <nav-tab-pane tab-id="availability" :class="{active: currentTab === 'availability'}">
                      <agenda :advisor-id="advisorData.info.id" :current-tab="currentTab"></agenda>
                    </nav-tab-pane>

                    <template v-if="userRole === 'is-admin'">
                      <nav-tab-pane tab-id="activities" :class="{active: currentTab === 'activities'}" class="nav-tabs-card">
                        <activity-log :current-tab="currentTab" :user-id="advisorData.info.user_id"></activity-log>
                      </nav-tab-pane>
                      <nav-tab-pane tab-id="e_curring" :class="{active: currentTab === 'payment'}" class="nav-tabs-card">
                        <advisor-settings
                            :current-tab="currentTab"
                            :accepts-appointments="advisorData.info.accepts_appointments"
                            :accepts-leads="advisorData.info.accepts_leads"
                            :appointment-price="advisorData.info.appointment_price"
                            :leads-limit="advisorData.info.leads_limit"
                            :user-id="advisorData.info.user_id" @data-change="onSubscriptionDataChange"></advisor-settings>
                      </nav-tab-pane>
                    </template>

                    <nav-tab-pane tab-id="notes" :class="{active: currentTab === 'notes'}" class="nav-tabs-card">
                      <notes :current-tab="currentTab" :user-id="advisorData.info.user_id"></notes>
                    </nav-tab-pane>
                  </template>
                </template>
              </nav-tabs>
            </template>
            <template v-slot:aside>
              <div class="py-5 text-center" v-if="loadingData">
                <ion-spinner></ion-spinner>
              </div>
              <card-inner-group v-else>
                <card-inner>
                  <user-card :name="advisorData.info.first_name+' '+advisorData.info.last_name" :badge-text="$t('Advisor')" :image="advisorData.info.dp" :initials="advisorData.info.initials">
                    <template v-slot:extras>
                      <span class="sub-text">{{advisorData.info.email}}</span>
                    </template>
                  </user-card>
                </card-inner>
                <card-inner class="card-inner-sm" v-if="['is-admin', 'is-company'].includes(userRole)">
                  <btn-toolbar class="justify-center">
                    <btn-toolbar-item v-if="userRole === 'is-admin'">
                      <nk-button type="" is-link class="btn-trigger" is-icon-only-button>
                        <nio-icon icon="ni-mail"></nio-icon>
                      </nk-button>
                    </btn-toolbar-item>
                    <btn-toolbar-item>
                      <nk-button v-on:click="F_changeAccountStatus([advisorData.info])" type="" is-link class="btn-trigger"
                                 v-tooltip="$t('Change status')"
                                 :class="advisorData.info.user_status === 0 ? 'text-success': 'text-danger'" is-icon-only-button>
                        <nio-icon :icon="advisorData.info.user_status === 0 ? 'ni-user-check-fill' : 'ni-user-cross-fill'"></nio-icon>
                      </nk-button>
                    </btn-toolbar-item>
                  </btn-toolbar>
                </card-inner>
                <card-inner>
                  <row class="text-center">
                    <template v-if="['is-admin', 'is-company'].includes(userRole)">
                      <column default="4">
                        <profile-stats :label="$t('Total')+' Leads'" :value="advisorData.stats.assigned_leads"></profile-stats>
                      </column>
                      <column default="4">
                        <profile-stats :label="$t('Returned')" :value="advisorData.stats.returned"></profile-stats>
                      </column>
                    </template>
                    <column default="4">
                      <profile-stats :label="$t('Appointments')" :value="advisorData.stats.assigned_appointments"></profile-stats>
                    </column>
                  </row>
                </card-inner>
                <card-inner>
                  <h6 class="overline-title-alt mb-2">{{ $t('Additional') }}</h6>
                  <row class="g-3">
                    <column default="6">
                      <span class="sub-text">{{$t('ID')}}:</span>
                      <span class="profile-ud-value">{{ advisorData.info.id }}</span>
                    </column>
                    <column default="6">
                      <span class="sub-text">{{$t('Last Login')}}:</span>
                      <span class="profile-ud-value">{{ advisorData.info.last_login.date }}</span>
                    </column>
                    <template v-if="userRole === 'is-admin'">
                      <column default="6">
                        <span class="sub-text">{{$t('Account')}}:</span>
                        <span :class="{'text-success' :advisorData.info.user_status === 1, 'text-danger' : advisorData.info.user_status === 0}">
                              {{ advisorData.info.user_status === 1 ? $t('Active') : advisorData.info.user_status === 0 ? $t('Inactive') : '' }}
                            </span>
                      </column>
                      <column default="6">
                        <span class="sub-text">{{$t('Registered')}} {{$t('At')}}:</span>
                        <span class="profile-ud-value">{{ advisorData.info.created_at }}</span>
                      </column>
                    </template>
                  </row>
                </card-inner>
              </card-inner-group>
            </template>
          </card-aside>
        </card>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {IonSpinner, onIonViewDidEnter, useIonRouter} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import {MainBlock, BlockHead} from "@core/layouts";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios"
import {Card, CardInner, CardInnerGroup} from "@core/components/cards";

import "bootstrap"

import {useRoute} from "vue-router"
import {ListInline, ListInlineItem} from "@core/components/list-inline";
import CardAside from "@core/layouts/card-aside/CardAside";
import NavTabs from "@core/components/nav-tabs/NavTabs";
import NavTabItem from "@core/components/nav-tabs/NavTabItem";
import NavTabPane from "@core/components/nav-tabs/NavTabPane";

import UserCard from "@/views/admin/advisors/components/UserCard"
import BtnToolbar from "@core/components/btn-toolbar/BtnToolbar";
import BtnToolbarItem from "@core/components/btn-toolbar/components/Item";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";
import ProfileStats from "@/views/admin/advisors/components/ProfileStats";
import PersonalInfo from "@/views/admin/advisors/components/PersonalInfo";
import ActivityLog from "@/views/admin/advisors/components/ActivityLog";
import AssignedLeads from "@/views/admin/advisors/components/AssignedLeads";
import AdvisorSettings from "@/views/admin/advisors/components/AdvisorSettings";
import useCommonFunc from "@/@core/comp-functions/common"
import Notes from "@/views/admin/advisors/components/Notes";
import useUser from '@core/comp-functions/User'
import useStoreHelper from '@/store/helper'
import AssignedAppointments from "@/views/admin/advisors/components/AssignedAppointments";
import Agenda from "@/views/admin/advisors/components/Agenda";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    Agenda,
    AssignedAppointments,
    Notes,
    AdvisorSettings,
    AssignedLeads,
    ActivityLog,
    PersonalInfo,
    ProfileStats,
    Column,
    Row,
    NkButton,
    NioIcon,
    BtnToolbarItem,
    BtnToolbar,
    CardInner,
    NavTabPane,
    NavTabItem,
    NavTabs,
    CardAside,
    ListInlineItem,
    ListInline,
    Card,
    CardInnerGroup,
    BlockContent,
    MainBlock,
    BlockHead,
    UserCard,
    IonSpinner,
  },
  setup() {

    const{updateObject} = useCommonFunc()
    const ionRouter = useIonRouter()
    const route  = useRoute()
    const{endPointByRole, userRole} = useStoreHelper()
    const{ changeAccountStatus} = useUser()

    let loadingData = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      //await getAdvisors(true)
    }

    // Get advisor profile
    let advisorData = reactive({
      info:{
        accepts_appointments: null,
        accepts_leads: null,
        appointment_price: '',
        house_no: "",
        afm_number: "",
        ch_com_number: "",
        city: "",
        company: "",
        created_at: "",
        dp: "",
        email: ".",
        first_name: "",
        id: 0,
        initials: '',
        last_login: {date: "", text: ""},
        last_name: "",
        leads_limit: '',
        phone_number: "",
        post_code: "",
        province: "",
        street: '',
        updated_at: "",
        user_id: 0,
        user_status: -1,
      },
      stats: {
        assigned_leads: '-',
        returned: '-',
        assigned_appointments: '-',
      },
    })
    const getProfile = () => {
      loadingData.value = true
      axios.get(endPointByRole+'/advisors/'+route.params.id+'/profile')
          .then( resp => {
            if(resp.data.success){
              updateObject(advisorData, resp.data.data)
            }
          })
          .catch(error => {
            console.log(error)
          })
      .then(()=> loadingData.value = false)
    }
    onMounted(() => getProfile())

    // Tabs
    let asideActive = ref(false)
    const asideStatus = () => {
      asideActive.value = !asideActive.value
    }
    let currentTab = ref( 'p_info' )
    const switchTab = (toTab) => {
      currentTab.value = toTab
      asideActive.value = false
    }

    watch( () => route.params.id, (n)=> {
      if(parseInt(n) > 0){
        currentTab.value = 'p_info'
        getProfile()
      }
    })

    onIonViewDidEnter(()=>{
      currentTab.value = route.params.tab || 'p_info'
    })

    const tabs = {
      p_info: {
        name: 'Personal',
        icon: 'ni-user-circle',
      },
      leads: {
        name: 'Leads',
        icon: 'ni-coin-alt-fill',
        required_roles: ['is-admin', 'is-company'],
      },
      appointments: {
        name: 'Appointments',
        icon: 'ni-list-check',
      },
      availability: {
        name: 'Availability',
        icon: 'ni-calendar-check',
      },
      activities: {
        name: 'Activities',
        icon: 'ni-activity',
        required_roles: ['is-admin'],
      },
      payment: {
        name: 'Settings',
        icon: 'ni-setting-alt',
        required_roles: ['is-admin'],
      },
      notes: {
        name: 'Notes',
        icon: 'ni-notes-alt',
      },
    }

    const onSubscriptionDataChange = (data) => {
      updateObject(advisorData.info, data)
    }

    const F_changeAccountStatus = ( _advisors, action ) => {
      changeAccountStatus(_advisors, 'advisor', action)
      .then(r=>{
        advisorData.info.user_status = r.status
      })
      .catch(()=>{})
    }

    return {
      advisorData,
      switchTab,
      asideActive,
      asideStatus,
      currentTab,
      tabs,
      F_changeAccountStatus,

      ionRouter,
      loadingData,
      onSubscriptionDataChange,

      pagination,
      paginationCallback,
      userRole,
    }
  }
});

</script>
