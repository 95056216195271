import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";
import {useI18n} from "vue-i18n";

export default () => {

    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    const toastMessage = async (message, tColor)=>{
        toastController.create({position: "top", duration: 3000, message: message, color: tColor}).then((t)=> t.present())
    }
    const changeAccountStatus = ( users, userType, action ) => {

        return new Promise((resolve, reject)=>{

            let alertMessage = ''
            let status = ''
            if(users.length > 1){
                alertMessage = i18n.t('action_confirmation',{action: i18n.t(action), count: users.length, type: i18n.t(userType)})
                status = (action === 'suspend') ? 0 : 1
            }else{
                let name = users[0].name ? users[0].name : users[0].first_name
                alertMessage = i18n.t('advisor_status_change_confirmation', {action: users[0].user_status === 0 ? 'activate' : 'suspend', person_name: name})
                status = (users[0].user_status === 0) ? 1 : 0
            }

            let ids = []
            for(let x in users){
                ids.push(users[x].user_id)
            }

            alertController.create({
                header: i18n.t(status === 0 ? 'Suspend' : 'Activate')+' '+i18n.t('account')+(users.length > 1 ? 's' : ''),
                cssClass: isDesktop ? 'alert-web' : '',
                message: alertMessage,
                buttons: [
                    {
                        text: i18n.t('Cancel'),
                        cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                    },
                    {
                        cssClass: isDesktop ? (status === 0 ? 'alert-btn-danger' : 'alert-btn-primary') : '',
                        text: i18n.t('Confirm'), handler: async () => {
                            const loading = await loadingController.create({message: i18n.t('Processing...')})
                            await loading.present()
                            axios.post('/api/admin/user/set_account_status', {ids: JSON.stringify(ids), status: status})
                                .then( resp => {

                                    let message = resp.data.success ? i18n.t('Thank you! action completed') : resp.data.message
                                    let color   = resp.data.success ? "primary" : "danger"
                                    if(resp.data.success){
                                        resolve({status: status, ids: ids})
                                    }
                                    else{
                                        reject(message)
                                    }
                                    toastMessage(message,color)
                                })
                                .catch(error => {
                                    const message = i18n.t('Error')+': '+(error.response ? error.response.status+' '+error.response.statusText : i18n.t('errors.general_error'))
                                    toastMessage(message, 'danger')
                                    reject(message)
                                })
                                .then( () => loading.dismiss())
                        }
                    },
                ]
            }).then((a)=> a.present())
        });
    }
    const deleteUsers = async ( users, userType ) => {

        return new Promise((resolve, reject)=>{

            let ids = []
            for(let x in users){
                ids.push( users[x].user_id)
            }

            alertController.create({
                cssClass: isDesktop ? 'alert-web' : '',
                header: `${i18n.t('Delete')} ${i18n.t(users.length > 1 ? userType+'s' : userType)}`,
                message: i18n.t('action_confirmation',{action: 'delete', count:users.length, type: i18n.t(users.length > 1 ? userType+'s': userType)}),
                buttons: [
                    {
                        text: i18n.t('Cancel'),
                        cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                    },
                    {
                        cssClass: isDesktop ? 'alert-btn-danger' : '',
                        text: i18n.t('Confirm'), handler: async () => {
                            const loading = await loadingController.create({message: i18n.t('Please wait...')})
                            await loading.present()
                            axios.post('/api/admin/user/delete', {ids: JSON.stringify(ids)})
                                .then( resp => {
                                    const message = resp.data.success ? i18n.t('Thank you! action completed') : resp.data.message
                                    const color   = resp.data.success ? "primary" : "danger"
                                    if(resp.data.success){
                                        resolve(ids)
                                    }
                                    else{
                                        reject(message)
                                    }
                                    toastMessage(message, color)
                                })
                                .catch(error => {
                                    const message = i18n.t('Error')+': '+(error.response ? error.response.status+' '+error.response.statusText : i18n.t('errors.general_error'))
                                    toastMessage(message, 'danger')
                                    reject(message)
                                })
                                .then( () => loading.dismiss())
                        }
                    },
                ]
            }).then((iA) => iA.present())
        })
    }
    const resendInvitationEmail = async ( user_id ) => {

        return new Promise((resolve, reject)=>{
            alertController.create({
                cssClass: isDesktop ? 'alert-web' : '',
                header: i18n.t('Resend invitation'),
                message: i18n.t('re_invitation_mail_confirmation'),
                buttons: [
                    {
                        text: i18n.t('Cancel'),
                        cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                    },
                    {
                        cssClass: isDesktop ? 'alert-btn-danger' : '',
                        text: i18n.t('Confirm'), handler: async () => {
                            const loading = await loadingController.create({message: i18n.t('Please wait...')})
                            await loading.present()
                            axios.put(`/api/user/${user_id}/resend_invitation`)
                                .then( resp => {
                                    const message = resp.data.success ? i18n.t('Thank you! action completed') : resp.data.message
                                    const color   = resp.data.success ? "primary" : "danger"
                                    if(resp.data.success){
                                        resolve()
                                    }
                                    else{
                                        reject(message)
                                    }
                                    toastMessage(message, color)
                                })
                                .catch(er => {
                                    const msg = er.response ? er.response.data?.message || er.status+' '+er.statusText : i18n.t('errors.general_error');
                                    toastMessage(msg, 'danger')
                                    reject(msg)
                                })
                                .then( () => loading.dismiss())
                        }
                    },
                ]
            }).then((iA) => iA.present())
        })
    }
    return{
        changeAccountStatus,
        deleteUsers,
        resendInvitationEmail,
    }
}