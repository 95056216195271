<template>
  <card>
    <div class="text-center" v-if="loading">
      <ion-spinner></ion-spinner>
    </div>
    <div v-else>
      <h6>{{ $t('Availability') }}</h6>
      <p class="text-muted" v-if="!availability.length">{{ $t('Not set') }}</p>
      <div class="row" v-else>
        <div v-for="day in availability" :key="'avl'+day.id" class="col-auto">
          <div class="border px-2 rounded mb-2 bg-secondary-dim">
            <span class="d-block nk-text-muted">{{ $t(daysNames[day.week_day] || '') }}</span>
            <span class="text-muted">{{ day.start_time+' - '+day.end_time}}</span>
          </div>
        </div>
      </div>
      <h6 class="mt-4">{{ $t('Calendar') }}</h6>
      <FullCalendar ref="calendarRef" :options="calendarOptions"></FullCalendar>
    </div>
  </card>
</template>

<script>
import {ref, reactive, watch} from "vue";
import axios from "@/libs/axios";
import {IonSpinner} from "@ionic/vue"
import Card from "@core/components/cards/Card";
import useStoreHelper from '@/store/helper'
import FullCalendar from "@fullcalendar/vue3"
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listGridPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import nlLocal from "@fullcalendar/core/locales/nl";
import $ from "jquery";

export default {
  components:{Card, FullCalendar, IonSpinner},
  props: {
    currentTab: String,
    advisorId: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  setup( props ){

    const daysNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const calendarRef = ref(null)
    let loading = ref(false)
    let availability = ref([])
    let events  = ref([])
    const{endPointByRole} = useStoreHelper()

    const getAvailabilityAndCalendar = () => {
      loading.value = true
      events.value.splice(0)
      axios.get(endPointByRole+'/advisors/'+props.advisorId+'/calendar')
          .then( resp => {
            if(resp.data.success){
              availability.value = resp.data.data.availability
              for(let x in resp.data.data.events){
                events.value.push(resp.data.data.events[x])
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .then(()=> loading.value = false)
    }

    watch( () => props.currentTab, (n) => {
      if(n === 'availability' && !events.value.length){
        getAvailabilityAndCalendar()
      }
    })

    let calendarOptions = reactive({
      plugins: [dayGridPlugin, timeGridPlugin, listGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      height: 800,
      contentHeight: 780,
      aspectRatio: 3,
      headerToolbar: {
        left: 'title prev,next',
        center: null,
        right: 'today dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      locales:[nlLocal],
      locale: (!localStorage.getItem('lang') ? 'nl' : localStorage.getItem('lang')),
      bootstrapFontAwesome: false,
      themeSystem: 'bootstrap',
      //rerenderDelay: 10000,
      eventMouseEnter: function(info) {
        $(info.el).popover({
          template: '<div class="popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
          title: info.event._def.title,
          content: info.event._def.extendedProps.description,
          placement: 'top',
        });
        info.event._def.extendedProps.description ? $(info.el).popover('show') : $(info.el).popover('hide');
      },
      eventMouseLeave: function(info) {
        $(info.el).popover('hide');
      },
      events: events.value,
      eventDidMount: function (/*info*/){
        return true//calendars.value.includes(info.event._def.extendedProps.calendar)
      },
    })

    return {
      availability,
      calendarRef,
      calendarOptions,
      daysNames,
      loading,
    }

  }
}
</script>

<style scoped>
.fc .fc-toolbar-title{
  font-size: 1rem;
  font-weight: 100;
}
</style>
