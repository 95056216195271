<template>
  <li>
    <router-link v-if="to" :to="to">
      <nio-icon :icon="icon" v-if="icon"></nio-icon>
      <span>{{ text }}</span>
    </router-link>
    <a href="javascript:;" v-else>
      <nio-icon :icon="icon" v-if="icon"></nio-icon>
      <span>{{ text }}</span>
    </a>

  </li>
</template>

<script>
import NioIcon from "@core/components/nio-icon/NioIcon";
export default {
  name: "ListItem",
  components: {NioIcon},
  props: {
    icon: String,
    text: String,
    to: {
      type: Object,
    }
  },
}
</script>
