<template>
  <card no-padding>
    <template v-slot:grouped>
      <card-inner>
        <div class="between-start flex-wrap flex-md-nowrap g-3">
          <div class="nk-block-text">
            <h6 class="fw-500">{{ $t('Accepts appointments?') }}</h6>
            <p class="text-muted">{{ acceptsAppointments === '1' ? $t('Yes') : $t('No')}}</p>
            <template v-if="acceptsAppointments === '1'">
              <h6 class="fw-500">{{ $t('Price')+'/'+$t('Appointment') }}</h6>
              <p class="text-muted">&euro;{{ appointmentPrice }}</p>
            </template>
          </div>
          <div class="nk-block-actions">
            <nk-button type="" is-icon-only-button="" class="btn-trigger" v-on:click="[setModalOpenStatus(true)]">
              <nio-icon icon="ni-edit"></nio-icon>
            </nk-button>
          </div>
        </div>
        <div class="my-4">
          <div class="nk-block-text">
            <h6 class="fw-500">{{ $t('Accepts leads?')}}</h6>
            <p class="text-muted">{{ acceptsLeads === '1' ? $t('Yes') : $t('No')}}</p>
          </div>
        </div>
        <div>
          <div class="nk-block-text">
            <h6 class="fw-500">Leads {{ $t('Limit') }}</h6>
            <p class="text-muted">
              <badge dim :content="leadsLimit" type="primary" class="font-weight-bold mr-1" style="font-size: 1rem"></badge> {{ $t('leads per week') }}</p>
          </div>
        </div>
      </card-inner>
      <card-inner v-if="advisorPaymentInfo.company_id < 1">
        <ion-spinner v-if="advisorPaymentInfo.spinner"></ion-spinner>
        <template v-else>
          <template v-if="!advisorPaymentInfo.mandates.length">
            <div class="alert alert-info">{{ $t('payment.mandate.not_signed_1') }}</div>
            <nk-button type="primary" size="sm" v-on:click="generateMandateLink" v-if="paymentProvider.toLowerCase() === 'mollie'">{{$t('Generate a new mandate signing link!')}}</nk-button>
          </template>
          <div class="between-center flex-wrap flex-md-nowrap g-3" v-for="(mandate,i) in advisorPaymentInfo.mandates" :key="'s_mandate'+i">
            <div class="nk-block-text">
              <h6> {{ mandate.type+' '+ $t('Mandate') }}</h6>
              <p class="text-muted">{{ $t('Advisor') }} {{ mandate.type }} {{ $t('mandate') }} {{ $t('information') }}</p>
              <div class="text-muted">
                <div class="nk-text-muted">{{ $t('Mandate') }} {{ $t('Code') }}: </div>
                <span>{{ mandate.code}}</span>
                <div class="nk-text-muted">{{ $t('Signed')+' '+$t('on')}}: </div>
                <span>{{ mandate.signed_date}}</span>
              </div>
            </div>
            <div class="mt-2" v-if="mandate.type === 'Mollie'">
              <nk-button type="primary" size="sm" v-on:click="revokeMandateModel(true, mandate)">{{$t('Revoke Mandate')}}</nk-button>
            </div>
          </div>
        </template>
      </card-inner>
    </template>
  </card>
  <ion-modal
      :is-open="isModalOpenRef"
      @didDismiss="setModalOpenStatus(false)"
      :css-class="isDesktop ? 'modal-web' : ''"
  >
    <i-modal :title="$t('Update Settings')" @modal-close="setModalOpenStatus(false)">
      <row>
        <column md="12" lg="6" class="mb-md-3">
          <form-group>
            <label class="overline-title" for="total_leads_input">Leads {{ $t('limit')+' '+$t('per week') }}</label>
            <b-input id="total_leads_input" v-model="dataUpdateObj.leads_limit"/>
          </form-group>
        </column>
      </row>
      <row class="mb-4">
        <column md="12" lg="6" class="mb-md-3">
          <form-group>
            <label class="overline-title">{{ $t('Accepts appointments?') }}</label>
            <div>
              <b-radio id="adv_a_ap_no" name="accepts_appointments" value="1" v-model="dataUpdateObj.accepts_appointments" label="Yes" class="mr-4"></b-radio>
              <b-radio id="adv_a_ap_yes" name="accepts_appointments" value="0" v-model="dataUpdateObj.accepts_appointments" label="No"></b-radio>
            </div>
          </form-group>
        </column>
        <column md="12" lg="6" v-if="dataUpdateObj.accepts_appointments === '1'">
          <form-group>
            <label class="overline-title">{{ $t('Price')+'/'+$t('Appointment') }} &euro;</label>
            <div>
              <b-input v-model="dataUpdateObj.appointment_price"></b-input>
            </div>
          </form-group>
        </column>
      </row>
      <row class="mb-4">
        <column md="12" lg="6" class="mb-md-3">
          <form-group>
            <label class="overline-title">{{ $t('Accepts leads?') }}</label>
            <div>
              <b-radio id="adv_a_leads_yes" name="accepts_leads" value="1" v-model="dataUpdateObj.accepts_leads" label="Yes" class="mr-4"></b-radio>
              <b-radio id="adv_a_leads_no" name="accepts_leads" value="0" v-model="dataUpdateObj.accepts_leads" label="No"></b-radio>
            </div>
          </form-group>
        </column>
      </row>
      <template v-slot:footer class="p-5">
        <nk-button type="primary" class="mr-2" v-on:click="updateAdvisorData">{{ $t('Update')}}</nk-button>
        <a href="javascript:;" class="link link-light" v-on:click="setModalOpenStatus(false)">{{ $t('Cancel') }}</a>
      </template>
    </i-modal>
  </ion-modal>

  <ion-modal
      :is-open="objRevokeMandate.modalOpen"
      @didDismiss="revokeMandateModel(false)"
      :css-class="isDesktop ? 'modal-web' : ''">
    <i-modal :title="$t('Revoke Mandate')" @modal-close="revokeMandateModel(false)">
      <form-group class="d-flex align-items-center">
        <b-check-box v-model="objRevokeMandate.data.generate_mandate_link" id="gn_m_link_c_box"></b-check-box>
        <label class="m-0 ml-1" for="gn_m_link_c_box">{{ $t('Generate a new mandate signing link!') }}</label>
      </form-group>
      <p class="text-warning">{{ $t('pages.admin_advisor_profile.mandate_revoke_warning') }}</p>
      <template v-slot:footer class="p-5">
        <nk-button type="primary" class="mr-2" v-on:click="revokeMandate">{{ $t('Revoke Mandate')}}</nk-button>
        <a href="javascript:;" class="link link-light" v-on:click="revokeMandateModel(false)">{{ $t('Cancel') }}</a>
      </template>
    </i-modal>
  </ion-modal>
  <ion-modal
      :is-open="newMandateURLModal.modalOpen"
      @didDismiss="newMandateURLModal.modalOpen=false"
      :css-class="isDesktop ? 'modal-web' : ''"
      :backdrop-dismiss="false">
    <i-modal :title="$t('Revoke Mandate')" @modal-close="newMandateURLModal.modalOpen=false">
      <p>{{ $t('pages.admin_advisor_profile.copy_new_mandate_link') }}</p>
      <code>{{newMandateURLModal.mandate_link}}</code>
      <template v-slot:footer class="p-5">
        <a href="javascript:;" class="link link-light" v-on:click="newMandateURLModal.modalOpen=false">{{ $t('Close') }}</a>
      </template>
    </i-modal>
  </ion-modal>
</template>

<script>
import {Card, CardInner} from "@core/components/cards";
import {Badge} from "@core/components";
import IModal from "@core/components/ion-modal/IModal";
import {alertController, IonModal, IonSpinner, isPlatform, loadingController, toastController} from "@ionic/vue"
import {Column,FormGroup,Row} from "@core/layouts";
import {reactive, ref, watch} from "vue";
import {BCheckBox, BInput, BRadio} from "@core/components/bp-form";
import axios from "@/libs/axios"
import {useI18n} from "vue-i18n";

export default {
  components: {Column, Row, BRadio, BCheckBox, Badge, BInput, FormGroup, CardInner, Card, IModal, IonModal, IonSpinner},
  props: {
    acceptsAppointments: null,
    acceptsLeads: null,
    appointmentPrice: null,
    currentTab: String,
    userId: null,
    leadsLimit: null,
  },
  emits: ['dataChange'],
  setup(props, {emit}){

    const{t} = useI18n()
    const isDesktop = isPlatform('desktop')
    let dataUpdateObj = reactive({
      accepts_appointments: props.acceptsAppointments,
      accepts_leads: props.acceptsLeads,
      appointment_price: props.appointmentPrice,
      leads_limit: props.leadsLimit,
    })

    let isModalOpenRef = ref(false)
    let objRevokeMandate = reactive({
      modalOpen: false,
      data:{
        generate_mandate_link: false,
        code: '',
        type: '',
      }
    })
    let newMandateURLModal = reactive({
      modalOpen: false,
      mandate_link: '',
    })
    let advisorPaymentInfo = reactive({
      mandates: [],
      company_id: 0,
      error_message: '',
      spinner: false,
    })
    const setModalOpenStatus = (s) => isModalOpenRef.value = s
    const updateAdvisorData = async () => {
      const loader = await loadingController.create({message: t('Please wait...')})
      await loader.present()
      axios.post('/api/admin/advisors/'+props.userId+'/update', dataUpdateObj)
      .then(resp => {
        if( resp.data.success ){
          setModalOpenStatus(false)
          emit('dataChange', dataUpdateObj)
          toastController.create({header: 'Success', message: t('Setting was updated'), color: "tertiary", duration: 3400}).then((r)=> r.present())
        }
        else{
          toastController.create({header: 'Error', message: resp.data.message, color: "danger", duration: 3400}).then((r)=> r.present())
        }
      })
      .catch(e => {
        toastController.create({header: 'Error', message: e.response.status+' '+e.response.statusText, color: "danger", duration: 3400}).then((r)=> r.present())
      })
      .then(() => loader.dismiss())
    }
    const paymentProvider = process.env.VUE_APP_PAYMENT_PROVIDER

    const revokeMandateModel = (open, mandate)=>{
      if(open){
        objRevokeMandate.data.generate_mandate_link = false
        objRevokeMandate.data.code = mandate.code
        objRevokeMandate.data.type = mandate.type
      }
      objRevokeMandate.modalOpen = open
    }
    const revokeMandate = async ()=>{
      const l = await loadingController.create({message: t('Processing...')})
      await l.present()
      axios.post('/api/admin/advisors/'+props.userId+'/revoke_mandate', objRevokeMandate.data)
      .then((r)=>{
        if(objRevokeMandate.data.generate_mandate_link){
          newMandateURLModal.mandate_link = r.data.data.url
          newMandateURLModal.modalOpen = true
        }
        else{
          toastController.create({header: t('Success'), message: t('pages.admin_advisor_profile.mandate_revoked'), duration: 4000, position: "top", color: 'primary'}).then((tt)=> tt.present())
        }
        objRevokeMandate.modalOpen = false
      })
      .catch(er=>{
        const msg = er.response ? er.response.status+' '+er.response.data.message : t('errors.general_error')
        toastController.create({header: t('Error'), message: msg, duration: 4000, position: "top", color: 'danger'}).then((tt)=> tt.present())
      })
      .then(()=> l.dismiss())
    }
    const getAdvisorPaymentInfo = async ()=>{
      advisorPaymentInfo.spinner = true
      advisorPaymentInfo.error_message = ''
      axios.get(`/api/admin/user/${props.userId}/mandate_info`)
      .then((r)=>{
        if(!r.data.success){
          advisorPaymentInfo.error_message = r.data.message
        }
        else{
          advisorPaymentInfo.mandates = r.data.data.mandates
          advisorPaymentInfo.company_id = r.data.data.company_id
        }
      })
      .catch(er=>{
        const msg = er.response ? er.response.status+' '+er.response.data.message : t('errors.general_error')
        toastController.create({header: t('Error'), message: msg, duration: 4000, position: "top", color: 'danger'}).then((tt)=> tt.present())
      })
      .then(()=> advisorPaymentInfo.spinner = false)
    }

    const generateMandateLink = async ()=>{

      const loading = await loadingController.create({message: t('Please wait...')})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let iAlert = await alertController.create({
        cssClass: isDesktop ? 'alert-web' : '',
        header: t('Generate mandate link'),
        message: t('pages.admin_advisor_profile.new_mandate_link_confirmation'),
        buttons: [
          {
            text: t('Cancel'),
            cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
          },
          {
            cssClass: isDesktop ? 'alert-btn-danger' : '',
            text: t('Confirm'), handler: () => {
              loading.present()
              axios.post('/api/admin/advisors/'+props.userId+'/create_mandate', {provider: paymentProvider})
                  .then( resp => {
                    if(!resp.data.success){
                      toast.message = t('Error')+': '+resp.data.message
                      toast.color = "danger"
                      toast.present()
                      return
                    }
                    newMandateURLModal.mandate_link = resp.data.data.url
                    newMandateURLModal.modalOpen = true
                  })
                  .catch(error => {
                    toast.message = t('Error')+': '+error.response.status+' '+error.response.statusText
                    toast.color = "danger"
                    toast.present()
                  })
                  .then( () => loading.dismiss())
            }
          },
        ]
      })
      await iAlert.present()
    }

    watch(()=> props.currentTab, (n)=>{
      if(n === 'payment'){
        getAdvisorPaymentInfo()
      }
    })

    return {
      advisorPaymentInfo,
      dataUpdateObj,
      generateMandateLink,
      isDesktop,
      isModalOpenRef,
      newMandateURLModal,
      objRevokeMandate,
      paymentProvider,
      revokeMandate,
      revokeMandateModel,
      setModalOpenStatus,
      updateAdvisorData,
    }
  }
}
</script>
